import EventBus from '@/util/EventBus.js'
import ToolsMenu from './landing-editor-ui/tools-menu/ToolsMenu.vue'
import LandingEditorCanvas from './landing-editor-ui/landing-editor-canvas/LandingEditorCanvas.vue'
import { Landing } from './classes/Landing'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import SaveBar from '@/components/Template/SaveBar/SaveBar.vue'
import HeaderBar from '@/components/Template/HeaderBar/HeaderBar.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingTemplateService from '@/services/landing-template.service'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import { ComponentFactory } from './utils/ComponentFactory'

import SimpleCancelSubscriptionProperties from '@/components/Template/LandingEditor/landing-editor-ui/tools-menu/draw-properties/simple-cancel-subscription-properties/SimpleCancelSubscriptionProperties.vue'
import MultimediaService from '@/services/multimedia.service.js'
import { EditorView } from '@codemirror/view'
import { basicSetup } from 'codemirror'
import { html } from '@codemirror/lang-html'
import getEnv from '@/util/env'
import JSZip from 'jszip'
import DOMPurify from 'dompurify'
import prettier from 'prettier'
import parserHtml from 'prettier/parser-html'
import FormDataTable from '@/classes/Form/FormDataTable.js'
import FormPreview from '@/components/FormPreview/FormPreview.vue'

export default {
  name: 'LandingEditor',
  components: {
    ToolsMenu,
    LandingEditorCanvas,
    SaveBar,
    HeaderBar,
    LandingPreview,
    OverlayManager,
    VideoPlayer,
    SimpleCancelSubscriptionProperties,
    FormPreview,
  },
  props: {
    landingTemplate: Object,
    localEdition: { type: Boolean, default: false },
    isUndoRedo: { type: Boolean, default: true },
    isSaveExit: { type: Boolean, default: false, required: false },
    showSaveNewTemplateActions: { type: Boolean, default: true, required: false },
  },
  data: function () {
    return {
      landing: null,
      saveBarProperties: new SaveBarProperties(),
      reRenderLanding: 0,
      urls: {
        redirectUrl: '/',
        preview: 'campaign/mailing/preview',
        store: 'mailing/store',
        send: 'mailing/send',
        update: 'mailing/update',
        paymentUrl: '/payments',
        submitUrl: '',
      },
      undoRedoManager: {
        show: true,
        initSteps: 1,
        limitSteps: 50,
        steps: [
          {
            step: 0,
            structure: {},
          },
        ],
        selectedStep: 0,
        canUndo: false,
        canRedo: false,
        user: this.$store.getters['auth/getUser'],
      },
      containerTiles: [
        {
          id: 1,
          text: '1',
          icon: 'container-type-1',
          slots: 1,
          leftCols: 12,
          rightCols: 12,
        },
        {
          id: 2,
          text: '2',
          icon: 'container-type-2',
          slots: 2,
          leftCols: 6,
          rightCols: 6,
        },
        {
          id: 3,
          text: '2/3 : 1/3',
          icon: 'container-type-2-1',
          slots: 2,
          leftCols: 8,
          rightCols: 4,
        },
        {
          id: 4,
          text: '1/3 : 2/3',
          icon: 'container-type-1-2',
          slots: 2,
          leftCols: 4,
          rightCols: 8,
        },
      ],
      selectedLayout: null,
      htmlString: '',
      loadingPreview: false,
      view: null,
      clickOnHtmlRightPreview: true,
      readySimpleCancelEditor: false,
      executeCancelSubsUpdatedEvent: true,
      showSimpleUnsubscribeComponent: true,
      zipImageFiles: [],
      addingMultimedia: false,
      prepared: true,
      formDataTable: new FormDataTable(),
      showFormPreviewFlag: true,
      showDialogSaveAsTemplate: false,
      saveAsTemplateName: '',
    }
  },
  watch: {
    landingTemplate (newTemplate) {
      if (this.localEdition) {
        this.landing = this.landingTemplate
        this.landing.isLocalEditMode = true
      } else {
        this.landing = new Landing(this.landingTemplate.content, this.landingTemplate.id, this.landingTemplate.name, this.landingTemplate.path, this.landingTemplate.htmlContent, this.landingTemplate.type)
      }
    },
    landing: {
      handler () {
        if (!this.user) {
          this.user = JSON.parse(localStorage.getItem('user'))
        }
        if (!this.hasUnsubscribe() && !this.user?.avoidUnsubscribeBlock) {
          this.initUnsubscribe()
        } else {
          this.initEmpty()
        }
      },
      deep: true,
    },
    isSaveExit (newIsSaveExit) {
      if (newIsSaveExit) {
        this.$emit('saveExit', this.landing)
        this.save()
      }
    },
  },
  computed: {
    customVars () {
      const items = []
      for (const property in this.landing.customVars) {
        items.push({ text: this.landing.customVars[property], value: '{' + property + '}' })
      }
      return items
    },
  },
  created () {
    if (this.localEdition) {
      this.landing = this.landingTemplate
      this.landing.isLocalEditMode = true
    } else {
      this.landing = new Landing(this.landingTemplate.content, this.landingTemplate.id, this.landingTemplate.name, this.landingTemplate.path, this.landingTemplate.htmlContent, this.landingTemplate.type)
    }

    this.$forceUpdate()

    const that = this

    setTimeout(() => {
      const setup = [...basicSetup]
      // eslint-disable-next-line no-unused-vars
      this.view = new EditorView({
        extensions: [
          setup,
          html(),
          EditorView.updateListener.of(function (e) {
            that.loadingPreview = true
            const parser = new DOMParser()
            const htmlDoc = parser.parseFromString(e.state.doc.toString().trim(), 'text/html')

            const scriptElement = htmlDoc.createElement('script')
            scriptElement.textContent = `
            function handleLinkClick(event) {
              event.preventDefault();
              var formId = event.target.dataset.formId;
              window.parent.postMessage({ type: 'linkClick', formId: formId }, '*');
            }
          `

            htmlDoc.body.appendChild(scriptElement)

            const s = new XMLSerializer()
            const htmlDocString = s.serializeToString(htmlDoc)
            that.htmlString = 'data:text/html;charset=utf-8,' + encodeURIComponent(htmlDocString)


            // template-form-preview


            that.landing.htmlContent = e.state.doc.toString()
            that.loadingPreview = false
            if (that.executeCancelSubsUpdatedEvent && that.$refs.simpleCancelSubsComponent) {
              that.$refs.simpleCancelSubsComponent.updatedHtmlContent()
            }
          }),
          EditorView.theme({
            '&': { 'max-height': '725px', 'max-width': '100%', overflow: 'auto' },
            '.cm-scroller': { overflow: 'auto' },
            '.cm-content, .cm-gutter': { minHeight: '200px' },
          }),
        ],
        parent: document.getElementById('code-editor'),
      })
      this.view.dispatch({ changes: { from: 0, insert: that.landing.htmlContent } })
      this.formatCodeAction()
      this.readySimpleCancelEditor = true
    })

    this.selectedLayout = this.containerTiles[3]
  },
  mounted () {
    this.saveBarProperties.templateName = this.landing.name

    if (this.landing.id === -1) {
      this.saveBarProperties.preview = false
    }

    EventBus.$on('undoRedoManager', () => {
      this.updateUndoRedo()
    })
    EventBus.$on('multimediaSelected', this.setComponentData)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('multimediaSelected', this.setComponentData)
    })

    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('multimediaSelected', this.setComponentData)
    })

    const that = this

    window.addEventListener('message', function (event) {
      if (event.data && event.data.type === 'linkClick') {
        var formId = event.data.formId
        that.showOverlayPreviewForm(formId)
      }
    })
  },
  methods: {
    async showOverlayPreviewForm (formId) {
      const user = this.$store.getters['auth/getUser']
      this.formDataTable.previewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + formId + '?token=' + user.token
      this.formDataTable.preview = true
      EventBus.$emit('showOverlayFormPreview')
      this.updateFormPreview()
    },
    updateFormPreview () {
      this.showFormPreviewFlag = false
      setTimeout(() => {
        this.showFormPreviewFlag = true
      })
    },
    addTextToMessage (content) {
      const fromSelectionIndex = this.view.state.selection.ranges[0].from
      this.view.dispatch({ changes: { from: fromSelectionIndex, insert: content } })
      this.formatCodeAction()
    },
    setComponentData (url, type, fileId) {
      if (this.addingMultimedia) {
        if (type === 'attachment') {
          const link = getEnv('VUE_APP_ASSETS_URL') + url
          this.insertUrl(link, this.$t('Ir al adjunto'))
        }

        if (type === 'form') {
          // const user = JSON.parse(localStorage.getItem('user'))
          // this.component.urlPreview[fileId] = getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + fileId + '?token=' + user.token
          this.insertUrl('[FORM_' + fileId + ']', this.$t('Ir al formulario'), fileId)
        }
        this.addingMultimedia = false
      }
    },
    // eslint-disable-next-line complexity
    async insertUrl (url, text, formId = null) {
      const formIdDataAttr = formId ? `data-form-id="${formId}"` : ''
      const content = `<p style="text-align:center; color:#3F4254;font-family: Helvetica,Arial,sans-serif;">
        <a
          style="text-align:center;color:#00a4bd;"
          href="${url}"
          target="_blank"
          onclick="handleLinkClick(event)"
          ${formIdDataAttr}
        >${text}</a>
      </p>`

      const fromSelectionIndex = this.view.state.selection.ranges[0].from
      const isDocContentEmpty = this.view.state.doc.toString().trim() === ''

      if (fromSelectionIndex === 0 && isDocContentEmpty) {
        this.view.dispatch({ changes: { from: 0, insert: content } })
      } else if (fromSelectionIndex === 0 && !isDocContentEmpty) {
        const parser = new DOMParser()
        const htmlDoc = parser.parseFromString(this.view.state.doc.toString().trim(), 'text/html')
        htmlDoc.body.innerHTML = htmlDoc.body.innerHTML + content
        const s = new XMLSerializer()
        const htmlDocString = s.serializeToString(htmlDoc)
        this.view.dispatch({ changes: { from: 0, to: this.view.state.doc.length, insert: htmlDocString } })
      } else if (fromSelectionIndex !== 0) {
        this.view.dispatch({ changes: { from: fromSelectionIndex, insert: content } })
      }
      this.formatCodeAction()
    },

    formatCodeAction () {
      setTimeout(() => {
        this.formatCode()
      }, 500)
    },

    formatCode () {
      const doc = this.view.state.doc.toString()
      const formatted = prettier.format(doc, {
        parser: 'html',
        plugins: [parserHtml],
        tabWidth: 2,
        useTabs: false,
      })

      this.view.dispatch({
        changes: { from: 0, to: this.view.state.doc.length, insert: formatted },
      })
    },

    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    formAction () {
      this.addingMultimedia = true
      this.showMultimedia('formularios')
    },
    attachmentAction () {
      this.addingMultimedia = true
      this.showMultimedia('adjuntos')
    },
    triggerFileInput () {
      this.$refs.htmlFileInput.click()
    },
    clearFileInput () {
      this.$refs.htmlFileInput.value = ''
    },
    handleFileChange (event) {
      EventBus.$emit('showLoading', true)
      const file = event.target.files[0]
      // get name from zip file without zip extension
      const indexOfLastDot = file.name.lastIndexOf('.')
      let folderName = file.name.substring(0, indexOfLastDot)
      folderName = this.removeNotAllowedCharsFromFileName(folderName)

      if (file) {
        const reader = new FileReader()
        reader.onload = (evt) => {
          this.zipImageFiles = []
          const zip = new JSZip()
          let htmlIndexContent = ''
          zip.loadAsync(evt.target.result).then(async (zip) => {
            const promises = []
            zip.forEach((relativePath, file) => {
              if (file.name === 'index.html') {
                promises.push(file.async('string').then((content) => {
                  htmlIndexContent = content
                }))
              }

              if (!file.dir && file.name.startsWith('img/') && /\.(jpe?g|png|gif)$/i.test(file.name)) {
                promises.push(file.async('blob').then((content) => {
                  // clean name without img/ at start
                  const fileName = this.removeNotAllowedCharsFromFileName(file.name.slice(4))
                  this.zipImageFiles.push({
                    filenameWithPath: file.name,
                    name: fileName,
                    content: content,
                    url: '',
                  })
                }))
              }
            })
            this.clearFileInput()
            await Promise.all(promises)
            if (htmlIndexContent === '') {
              EventBus.$emit('showLoading', false)
              EventBus.$emit('showAlert', 'danger', this.$t('El archivo').toString() + ' index.html ' + this.$t('no existe en el zip').toString())
              return
            }

            if (this.zipImageFiles.length === 0) {
              EventBus.$emit('showLoading', false)
              EventBus.$emit('showAlert', 'danger', this.$t('La carpeta').toString() + ' img ' + this.$t('no existe en el zip').toString())
              return
            }

            const regExp = /src="img\/.*?"|src='img\/.*?'/gm
            const matches = htmlIndexContent.match(regExp)

            const uniques = [...new Set(matches)]
            const cleanImageFileSources = uniques.map((file) => {
              const match = /src=["'](img\/[^"']+)["']/g.exec(file)[1]
              return match
            })

            const fileNamesWithPath = this.zipImageFiles.map((zipImageFile) => {
              return zipImageFile.filenameWithPath
            })

            const notFoundImages = cleanImageFileSources.filter((fileSource) => {
              return !fileNamesWithPath.includes(fileSource)
            })

            // if matches and uniques
            if (matches && notFoundImages.length > 0) {
              EventBus.$emit('showLoading', false)
              const imageList = '<ul>' + notFoundImages.map(i => '<li>' + i + '</li>').join('') + '</ul>'
              EventBus.$emit('showAlert', 'danger', this.$t('El archivo está incompleto. Faltan las imágenes').toString() + ': ' + imageList)
              this.clearFileInput()
              return
            }

            if (this.zipImageFiles.length > 0) {
              this.uploadIndexAndImageFilesFromZip(htmlIndexContent, folderName)
            } else {
              EventBus.$emit('showLoading', false)
            }
          }).catch((e) => {
            this.clearFileInput()
            EventBus.$emit('showLoading', false)
            console.error('Error reading zip file: ', e)
          })
        }
        reader.readAsArrayBuffer(file)
      } else {
        EventBus.$emit('showLoading', false)
      }
    },

    removeNotAllowedCharsFromFileName (filename) {
      const parts = filename.split('.')
      const extension = parts.length > 1 ? parts.pop() : ''
      let name = parts.join('.')
      name = name.replace(/[_]+/g, '-')
      name = name.replace(/[^a-zA-Z0-9\s-]+/g, '')
      name = name.replace(/ /g, '-')
      name = name.trim()
      name = name.replace(/[\s-]+/g, '-')
      return extension ? `${name}.${extension}` : name
    },

    async uploadIndexAndImageFilesFromZip (htmlIndexContent, folderName) {
      this.prepared = false
      const checkFilePromise = []
      let newFolderName = ''
      checkFilePromise.push(MultimediaService.createDirectory({ name: folderName, path: 'imagenes', createNewDirectoryIfExists: true }).then((res) => {
        newFolderName = res.newDirectoryName
      }))

      await Promise.all(checkFilePromise)

      const promises = []
      this.fileUploadRequests = []
      this.zipImageFiles.forEach(fileObj => {
        const formData = new FormData()
        formData.append('file', fileObj.content, fileObj.name)
        formData.append('path', 'imagenes/' + newFolderName)
        promises.push(MultimediaService.uploadFile(formData).then((res) => {
          this.fileUploadRequests.push(res)
        }, (error) => {
          MultimediaService.errorResponse(
            error.response.status,
            this.$t('Error al subir imágenes del archivo zip'),
            this.$t(error.response.data.code),
          )
          EventBus.$emit('showLoading', false)
        }))
      })
      await Promise.all(promises)

      this.fileSelectionRequest = []
      this.fileSelectionResponses = []
      if (this.fileUploadRequests.length > 0) {
        this.fileUploadRequests.forEach((fileUploadResponse) => {
          const params = {
            path: fileUploadResponse.file.path,
            filename: fileUploadResponse.file.name,
          }
          this.fileSelectionRequest.push(MultimediaService.selectedFile(params).then(
            (response) => {
              this.fileSelectionResponses.push(response)
            }, (error) => {
              MultimediaService.errorResponse(
                error.response.status,
                this.$t('Error al subir imágenes del archivo zip'),
                this.$t(error.response.data.code),
              )
            }))
        })
        await Promise.all(this.fileSelectionRequest)
        this.zipImageFiles.forEach((imageFile, index) => {
          const indexOfLastDot = imageFile.name.lastIndexOf('.')
          const filenameWithoutExtension = imageFile.name.substring(0, indexOfLastDot)

          if (this.fileSelectionResponses.length > 0) {
            const foundFile = this.fileSelectionResponses.find((selectedFileData) => { return selectedFileData.fileInfo.name.toLowerCase() === filenameWithoutExtension.toLowerCase() })
            this.zipImageFiles[index].url = getEnv('VUE_APP_ASSETS_URL') + foundFile.url
          }
        })

        const replacements = {}
        this.zipImageFiles.forEach((file) => {
          replacements[file.filenameWithPath] = file.url
        })
        const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')
        const newContent = htmlIndexContent.replace(pattern, (match) => {
          return replacements[match]
        })

        const cleanContent = DOMPurify.sanitize(newContent)

        this.view.dispatch({ changes: { from: 0, to: this.view.state.doc.length, insert: cleanContent } })
        this.landing.htmlContent = cleanContent
        this.formatCodeAction()
        setTimeout(() => {
          this.prepared = true
          EventBus.$emit('showLoading', false)
        }, 500)
      } else {
        this.loadingPreview = false
        EventBus.$emit('showLoading', false)
      }
    },

    updatedHtmlCode (htmlContent) {
      this.executeCancelSubsUpdatedEvent = false
      this.landing.htmlContent = htmlContent
      this.view.dispatch({ changes: { from: 0, to: this.view.state.doc.length, insert: this.landing.htmlContent } })
      this.formatCodeAction()
      setTimeout(() => {
        this.executeCancelSubsUpdatedEvent = true
      }, 2000)
    },
    updateContent (htmlContent) {
      this.landing.htmlContent = htmlContent
      this.view.dispatch({ changes: { from: 0, insert: this.landing.htmlContent } })
      this.formatCodeAction()
    },

    async updateUndoRedo () {
      if (this.isLessThanLastStep(this.undoRedoManager.selectedStep, this.undoRedoManager.steps.length - 1)) {
        this.undoRedoManager.selectedStep = this.undoRedoManager.steps[this.undoRedoManager.steps.length - 1].step
      }

      if (this.isEqualLandingStructure(this.landing.structure, this.undoRedoManager.steps[this.undoRedoManager.steps.length - 1].structure)) {
        return
      }

      this.undoRedoManager.selectedStep = this.undoRedoManager.selectedStep + 1
      this.undoRedoManager.steps.push({
        step: this.undoRedoManager.selectedStep,
        structure: JSON.parse(JSON.stringify(this.landing.structure)),
      })

      this.canUndoRedo()
    },
    isEqualLandingStructure (structure1, structure2) {
      if (JSON.stringify(structure1) === JSON.stringify(structure2)) {
        return true
      }

      return false
    },
    isLessThanLastStep (selectedStep, stepsLength) {
      if (selectedStep < stepsLength) {
        return true
      }

      return false
    },
    canUndoRedo () {
      if ((this.undoRedoManager.steps.length - 1) > this.undoRedoManager.initSteps) {
        this.undoRedoManager.canUndo = true
      } else {
        this.undoRedoManager.canUndo = false
      }

      if ((this.undoRedoManager.steps.length - 1) !== this.undoRedoManager.selectedStep) {
        this.undoRedoManager.canRedo = true
      } else {
        this.undoRedoManager.canRedo = false
      }

      if (this.undoRedoManager.selectedStep <= 1) {
        this.undoRedoManager.canUndo = false
      }
    },
    async undo () {
      this.undoRedoManager.selectedStep = this.undoRedoManager.selectedStep - 1
      this.landing.structure = JSON.parse(JSON.stringify(this.undoRedoManager.steps[this.undoRedoManager.selectedStep].structure))
      this.reRenderLanding += 1
      await this.canUndoRedo()
    },
    async redo () {
      this.undoRedoManager.selectedStep = this.undoRedoManager.selectedStep + 1
      this.landing.structure = JSON.parse(JSON.stringify(this.undoRedoManager.steps[this.undoRedoManager.selectedStep].structure))
      this.reRenderLanding += 1
      await this.canUndoRedo()
    },
    hasUnsubscribe () {
      if (this.landing.structure.containers.length === 0) return false
      return this.landing.structure.containers.reduce((prev, nextC) => {
        return prev || nextC.slots.reduce((prev, nextS) => {
          return prev || nextS.reduce((prev, nextB) => {
            return prev || nextB.type === 'cancel-subscription'
          }, false)
        }, false)
      }, false)
    },
    initEmpty () {
      if (this.landing.structure.containers.length > 0) return false
      const newContainer = ComponentFactory.create({
        distribution: undefined,
        slots: 1,
        type: 'container',
      })
      this.landing.structure.containers.push(newContainer.getStructure())
      this.showSimpleUnsubscribeComponent = false
    },
    initUnsubscribe () {
      if (!this.user) {
        this.user = JSON.parse(localStorage.getItem('user'))
      }
      const newContainer = ComponentFactory.create({
        distribution: undefined,
        slots: 1,
        type: 'container',
      })
      const len = this.landing.structure.containers.push(newContainer.getStructure())
      const newComponent = ComponentFactory.create({
        type: 'cancel-subscription',
        isCountryEu: this.user.isCountryEu,
      })
      this.landing.structure.containers[len - 1].slots[0].push(newComponent.getStructure())
      EventBus.$emit('showPropertiesTabEvent', newComponent.type, len - 1, 0, newComponent.id)
      this.$nextTick(() => {
        document.getElementById('cancel-subscription-component-' + newComponent.id)?.click()
      })
    },
    getPreviewAndSave () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showAndSavePreview()
      })
    },
    prepareCreateLandingFromOuterActions () {
      this.saveAsTemplateName = ''
      this.showDialogSaveAsTemplate = true
    },
    createLandingFromOuterActions () {
      this.landing.name = this.saveAsTemplateName
      this.createLanding()
    },
    changeTemplateName () {
      this.landing.name = this.saveBarProperties.templateName
    },
    openOverlay () {
      EventBus.$emit('showOverlay')
    },
    createLanding (auto = false) {
      LandingTemplateService.create({
        name: this.landing.name,
        content: this.landing.structure,
        path: this.landing.path,
        htmlContent: this.landing.htmlContent,
        type: this.landing.type,
      }).then(
        (response) => {
          if (response.error) {
            EventBus.$emit('showAlert', 'danger', this.$t(response.error_message))
            this.confirmCampaignExit = true
            this.$router.push({ path: this.urls.redirectUrl })
            EventBus.$emit('flashCampaign', this.campaign)
          } else {
            this.landing.id = response.landingTemplate.id
            LandingTemplateService.save({
              id: response.landingTemplate.id,
              name: this.landing.name,
              structure: this.landing.structure,
              path: this.landing.path,
              htmlContent: this.landing.htmlContent,
              type: this.landing.type,
            }).then(
              () => {
                this.saveBarProperties.preview = true
                if (!auto) {
                  EventBus.$emit('showAlert', 'success', this.$t('La plantilla se creó correctamente.'))
                }
              },
              (error) => {
                LandingTemplateService.errorResponse(
                  error.response.status,
                  this.$t('Error al intentar crear la plantilla'),
                  this.$t(error.response.data.message),
                )
              },
            )
            .finally(() => {
              this.saveBarProperties.loadingSave = false
              this.showDialogSaveAsTemplate = false
            })
          }
        },
        (error) => {
          LandingTemplateService.errorResponse(
            error.response.status,
            this.$t('Error al intentar crear la plantilla'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.saveBarProperties.loadingSave = false
      })
    },
    updateLanding (auto = false) {
      LandingTemplateService.save({
        id: this.landing.id,
        name: this.landing.name,
        structure: this.landing.structure,
        path: this.landing.path,
        htmlContent: this.landing.htmlContent,
        type: this.landing.type,
      }).then(
        (response) => {
          if (response.error) {
            EventBus.$emit('showAlert', 'danger', this.$t(response.error_message))
          } else {
            if (!auto) {
              EventBus.$emit('showAlert', 'success', this.$t('La plantilla se actualizó correctamente.'))
            }
          }
        },
        (error) => {
          LandingTemplateService.errorResponse(
            error.response.status,
            this.$t('Error al intentar actualizar la plantilla'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.saveBarProperties.loadingSave = false
      })
    },
    save (auto = false) {
      if (this.landing.id === -1) {
        this.createLanding(auto)
      } else {
        this.updateLanding(auto)
      }
      this.$emit('save', this.landing)
    },
  },
}
